import React, {useState, useEffect} from 'react';
import {DataFrame} from '@grafana/data';
import styled from 'styled-components';
import {MapApi} from '@unfolded/map-sdk';
import {processDataFrame} from '../utils/process-data';

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%);
`;

const StyledButton = styled.button`
  color: #fff;
  background: #3d71d9;
  border: 0;
  padding: 0px 16px;
  height: 32px;
  line-height: 30px;

  &.open-btn {
    margin-top: 16px;
  }

  &:hover {
    background: #5a86de;
  }
`;

const SaveToCloudWrapper = styled.div`
  position: relative;
  padding: 36px;
  background: #000;

  .btn-close {
    position: absolute;
    top: 36px;
    right: 36px;
    width: 24px;
    height: 24px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px'%0Awidth='24' height='24'%0AviewBox='0 0 24 24'%0Astyle=' fill:%23fff;'%3E%3Cpath d='M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z'%3E%3C/path%3E%3C/svg%3E");
    &:hover {
      cursor: pointer;
    }
  }

  .loader-container {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);

    &.visible {
      display: initial;
    }
  }
  .content {
    display: flex;
    flex-direction: column;

    &.overlay {
      opacity: 0.4;
    }
    .content-description {
      color: rgba(204, 204, 220, 0.65);
      display: block;
      width: 70%;

      a {
        color: cornflowerblue;
        text-decoration-line: underline;
        word-break: break-all;
      }
    }
    .input-wrapper {
      display: flex;

      .wrap-half {
        display: flex;
        flex-direction: column;
        width: 50%;

        &:last-child {
          margin-left: 32px;
        }
      }
    }
    input {
      margin-bottom: 8px;
      height: 36px;
    }
    textarea {
      color: #fff;
      background-color: #111217;
      border: none;
    }
    button {
      width: 144px;
      span {
        text-align: center;
        width: 100%;
        display: block;
      }
    }
    .created-map-url {
      margin-top: 16px;
      display: flex;
      flex-direction: column;

      a {
        color: rgb(85, 88, 219);
      }
    }
    .error {
      margin-top: 16px;
      display: flex;
      align-items: center;
      i {
        width: 14px;
        background-repeat: no-repeat;
        height: 14px;
        display: inline-block;
        margin-right: 8px;
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 50 50' style='enable-background:new 0 0 50 50;' xml:space='preserve'%3E%3Ccircle style='fill:%23D75A4A;' cx='25' cy='25' r='25'/%3E%3Cpolyline style='fill:none;stroke:%23FFFFFF;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;' points='16,34 25,25 34,16 '/%3E%3Cpolyline style='fill:none;stroke:%23FFFFFF;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;' points='16,16 25,25 34,34 '/%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E%0A");
      }
      color: #d75a4a;
    }
  }

  .loader,
  .loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }
  .loader {
    margin: 60px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid #333;
    border-right: 1.1em solid #333;
    border-bottom: 1.1em solid #333;
    border-left: 1.1em solid #ffffff;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
  }
  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

export const SaveMapComponent = ({
  mapInstance,
  dataFrame,
  createLinePath,
  animateTrips
}: {
  mapInstance?: MapApi;
  dataFrame: DataFrame[];
  createLinePath: boolean;
  animateTrips: boolean;
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [accessToken, setAccessToken] = useState('');
  const [mapName, setMapName] = useState('');
  const [mapDescription, setMapDescription] = useState('');
  const [mapId, setMapId] = useState('');
  const [isMapSaved, setIsMapSaved] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    if (!isMapSaved) {
      const mapConfig = mapInstance?.getMapConfig();
      if (mapConfig) {
        saveMapToCloud(mapConfig, dataFrame, createLinePath, animateTrips)
          .then((mapId) => {
            setMapId(mapId);
            setIsMapSaved(true);
          })
          .catch((error) => {
            console.error(error);
            setIsMapSaved(true);
            setErrorMsg(error);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMapSaved]);

  const handleSubmit = () => {
    setIsMapSaved(false);
  };

  const saveMapToCloud = async (
    mapConfig: any,
    dataFrames: DataFrame[],
    createLinePath: boolean,
    animateTrips: boolean
  ) => {
    if (accessToken) {
      const processedData = processDataFrame(dataFrames, createLinePath, animateTrips);
      let addedDatasetIds: string[] = [];
      for (let i = 0; i < processedData.length; i++) {
        await postDataToCloud(processedData[i], accessToken, `Grafana-Query-${i + 1}`).then(
          (response) => {
            if (response.id) {
              addedDatasetIds.push(response.id);
            } else {
              throw `Error: ${response.message} - ${response.details}`;
            }
          }
        );
      }

      if (addedDatasetIds.length) {
        // If mapConfig is defined replace incognito dataset IDs with the ones created from the DataSDK
        if (mapConfig) {
          try {
            mapConfig = mapConfig;
            mapConfig.config.visState.datasets.fieldDisplayNames = {};
            for (let i = 0; i < addedDatasetIds.length; i++) {
              mapConfig.config.visState.layers[i].config.dataId = addedDatasetIds[i];
              mapConfig.config.visState.datasets.fieldDisplayNames[addedDatasetIds[i]] = {};
            }
          } catch (error) {
            console.error(error);
          }
        }

        const mapData = {
          name: mapName ? mapName : 'Grafana Map',
          description: mapDescription ? mapDescription : 'Grafana Map description',
          latestState: {
            id: '5027f0ec-fbc1-4412-b8e4-542f0e9dafde',
            data: mapConfig
          },
          datasets: addedDatasetIds
        };
        const mapId = await postMapToCloud(mapData, accessToken).then((response) => {
          if (response.id) {
            return response.id;
          } else {
            throw `Error: ${response.message} - ${response.details}`;
          }
        });

        return mapId;
      } else {
        throw `No data has been added to the map`;
      }
    } else {
      throw `API Authorization token is not defined`;
    }
  };

  /**
   * Posts the created map in Grafana to the DataSDK API
   * @param data Map data to be passed to the DataSDK api
   * @param token API authorization token
   */
  const postMapToCloud = async (data: any, token: string) => {
    const url = 'https://data-api.foursquare.com/v1/maps';
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(data)
    });
    return response.json(); // parses JSON response into native JavaScript objects
  };

  /**
   * Posts the data from the queries to the DataSDK API
   * @param data Map data to be passed to the DataSDK api
   * @param token API authorization token
   * @param datasetName Name of the dataset
   */
  const postDataToCloud = async (data: any, token: string, datasetName = 'Grafana-Query-1') => {
    const url = `https://data-api.foursquare.com/v1/datasets/data?name=${datasetName}`;
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(data)
    });
    return response.json(); // parses JSON response into native JavaScript objects
  };

  return (
    <Container>
      {!isVisible && (
        <StyledButton
          className={'open-btn ' + (!isVisible ? 'visible' : '')}
          onClick={() => setIsVisible(true)}
        >
          Save to Foursquare Cloud
        </StyledButton>
      )}
      {isVisible && (
        <SaveToCloudWrapper>
          <div className="btn-close" onClick={() => setIsVisible(false)}></div>
          <div className={'loader-container ' + (!isMapSaved ? 'visible' : '')}>
            <div className="loader">Loading...</div>
          </div>

          <div className={'content ' + (!isMapSaved ? 'overlay' : '')}>
            <p className="content-description">
              Save the map to the Foursquare Cloud. Make sure to get the{' '}
              <a href="https://studio.foursquare.com/tokens.html" target="_blank" rel="noreferrer">
                valid Access Token
              </a>
            </p>

            <div className="input-wrapper">
              <div className="wrap-half">
                <input
                  name="mapToken"
                  required
                  placeholder="Add Access Token"
                  value={accessToken}
                  onChange={(e) => setAccessToken(e.target.value)}
                />
                <input
                  name="mapName"
                  required
                  placeholder="Add map name"
                  value={mapName}
                  onChange={(e) => setMapName(e.target.value)}
                />
                <textarea
                  name="mapDescription"
                  required
                  placeholder="Add map description"
                  value={mapDescription}
                  onChange={(e) => setMapDescription(e.target.value)}
                />
              </div>
              <div className="wrap-half">
                <StyledButton onClick={() => handleSubmit()}>Save map copy</StyledButton>
                {mapId && (
                  <div className="created-map-url">
                    <span>Created Map URL:</span>
                    <a
                      href={`https://studio.foursquare.com/map/${mapId}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://studio.foursquare.com/map/{mapId}
                    </a>
                  </div>
                )}
              </div>
            </div>
            {errorMsg && (
              <span className="error">
                <i />
                {errorMsg}
              </span>
            )}
          </div>
        </SaveToCloudWrapper>
      )}
    </Container>
  );
};
